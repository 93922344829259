<template>
  <el-form ref="form" :model="form" @submit.prevent.native="() => {}">
    <el-alert type="info" :closable="false" show-icon>
      <span
        v-html="
          $t('corporate_accounts_management.add_page.company_details.alert')
        "
      ></span>
    </el-alert>
    <base-section
      v-if="
        $auth.isOspAdmin() &&
          $route.query.partnerName &&
          $route.query.partnerOspId
      "
      :title="$t('general.label.partner')"
    >
      {{ $route.query.partnerName }}
    </base-section>
    <account-section
      ref="accountSection"
      id="accountSection"
      v-model="form"
    ></account-section>
    <template v-if="form.showExtraDetails">
      <company-address-section
        v-model="form"
        ref="companyAddressSection"
        id="companyAddressSection"
      ></company-address-section>
      <company-legal-section
        v-model="form"
        :edit="false"
        ref="companyLegalSection"
        id="companyLegalSection"
      ></company-legal-section>
      <company-financial-section
        v-model="form"
        :edit="false"
      ></company-financial-section>
      <security-section v-model="form"></security-section>
    </template>
  </el-form>
</template>

<script>
import AccountSection from '@/components/sections/corporateAccount/AccountSection'
import CompanyAddressSection from '@/components/sections/company/CompanyAddressSection'
import CompanyLegalSection from '@/components/sections/company/CompanyLegalSection'
import CompanyFinancialSection from '@/components/sections/company/CompanyFinancialSection'
import SecuritySection from '@/components/sections/corporateAccount/SecuritySection'

export default {
  name: 'CompanyDetails',
  components: {
    AccountSection,
    CompanyAddressSection,
    CompanyLegalSection,
    CompanyFinancialSection,
    SecuritySection
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {}
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>
