var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"form",attrs:{"model":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('el-alert',{attrs:{"type":"info","closable":false,"show-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t('corporate_accounts_management.add_page.company_details.alert')
      )}})]),(
      _vm.$auth.isOspAdmin() &&
        _vm.$route.query.partnerName &&
        _vm.$route.query.partnerOspId
    )?_c('base-section',{attrs:{"title":_vm.$t('general.label.partner')}},[_vm._v(" "+_vm._s(_vm.$route.query.partnerName)+" ")]):_vm._e(),_c('account-section',{ref:"accountSection",attrs:{"id":"accountSection"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),(_vm.form.showExtraDetails)?[_c('company-address-section',{ref:"companyAddressSection",attrs:{"id":"companyAddressSection"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('company-legal-section',{ref:"companyLegalSection",attrs:{"edit":false,"id":"companyLegalSection"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('company-financial-section',{attrs:{"edit":false},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('security-section',{model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }