<template>
  <base-section
    :title="$t('company_settings.details_page.security_section.title')"
    :key="key"
  >
    <base-checkbox
      :label="$t('corporate_accounts_management.add_page.company_details.security.two-factor_label')"
      :explanation="$t('corporate_accounts_management.add_page.company_details.security.two-factor_explanation')"
      v-model="form.requires2fa"
      style="margin-top: -10px;"
    />
    <base-checkbox
      :label="$t('company_settings.details_page.security_section.external_reference_mandatory.label')"
      :explanation="$t('company_settings.details_page.security_section.external_reference_mandatory.explanation')"
      v-model="form.externalReferenceMandatory"
      style="margin-top: -10px;"
    />
  </base-section>
</template>
<script>

export default {
  name: 'SecuritySection',
  data () {
    return {
      key: ''
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<style scoped>

</style>
