<template>
  <base-section
    :title="$t('user_management.section.account.title')"
    :key="key"
  >
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.input_label.name')"
          ref="name"
          prop="name"
          :required="true"
          :error="error"
          v-model="form.name"
          :validations="$v.form.name"
          name="name"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
      <el-col :span="12">
        <base-input
          :key="extIdKey"
          :label="$t('partners_management.add_partner_page.details.input_label.ext_id')"
          ref="extId"
          prop="extId"
          v-model="form.extId"
          :validations="$v.form.extId"
          :disabled="form.extIdEqualToName"
          v-mask="extIdMask"
          name="extId"
          @keyupEnter="$emit('submit')"
        ></base-input>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :offset="12" :span="12">
        <base-checkbox
          :label="$t('partners_management.add_partner_page.details.checkbox_label.ext_id')"
          v-model="form.extIdEqualToName"
        ></base-checkbox>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.label.contract_number')"
          ref="contract_number"
          prop="contract_number"
          :error="error"
          v-model="form.contractId"
          name="contract_number"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
    </el-row>
    <base-button
      v-if="form.showExtraDetails"
      class="extra-details-button"
      :label="$t('user_management.section.personal.hide_extra_details_button')"
      prepend-icon="ri-arrow-up-s-line"
      @click.native="toggleExtraDetails"
    />
    <base-button
      v-else
      class="extra-details-button"
      :label="$t('user_management.section.personal.add_extra_details_button')"
      prepend-icon="ri-arrow-down-s-line"
      @click.native="toggleExtraDetails"
    />
  </base-section>
</template>
<script>

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AccountSection',
  data () {
    const mask = []
    for (let i = 1; i < 101; i++) {
      mask.push('#'.repeat(i))
    }
    return {
      key: '',
      error: '',
      extIdKey: '',
      extIdMask: {
        mask: mask,
        tokens: {
          '#': { pattern: /[a-zA-Z0-9\s_-]/, transform: v => v.replaceAll(/\s/igu, '_') }
        }
      }
    }
  },
  props: {
    value: {
      type: Object
    },
    showExtra: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  watch: {
    'form.name' (v) {
      if (this.form.extIdEqualToName) {
        this.form.extId = v.replaceAll(' ', '_')
        this.extIdKey = this.$key()
      }
    },
    'form.extIdEqualToName' (v) {
      if (v) {
        this.form.extId = this.form.name.replaceAll(' ', '_')
        this.extIdKey = this.$key()
      } else {
        this.form.extId = ''
        this.extIdKey = this.$key()
      }
    }
  },
  methods: {
    toggleExtraDetails () {
      this.form.showExtraDetails = !this.form.showExtraDetails
    }
  }
}
</script>
