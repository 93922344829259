<template>
  <page-layout
    :title="$t('corporate_accounts_management.view_all_page.title')"
    :sub-title="$t('corporate_accounts_management.view_all_page.subtitle')"
    :fixed-footer="true"
    id="corporateAccountsPage"
  >
    <template #header-actions>
      <base-button
        type="primary"
        prepend-icon="ri-add-line"
        :disabled="$auth.isOspAdmin() && !filters.partnerOspId"
        :label="
          $t(
            'corporate_accounts_management.view_all_page.button_add_corporate_account'
          )
        "
        @click="handleAddCorporateAccount"
      />
    </template>

    <div class="filters">
      <el-form
        ref="form"
        class="filters-form"
        :inline="true"
        :model="form"
        @submit.prevent.native="fetchData"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              class="search"
              ref="search"
              prop="search"
              v-model.trim="form.search"
              name="search"
              auto-complete="off"
              :placeholder="
                $t('corporate_accounts_management.view_all_page.input_search')
              "
            >
              <template #prependInput>
                <i class="ri-search-line"></i>
              </template>
            </base-input>
          </el-col>
          <el-col :span="12">
            <base-checkbox
              v-model="form.inactive"
              :label="
                $t('corporate_accounts_management.view_all_page.input_inactive')
              "
            ></base-checkbox>
          </el-col>
        </el-row>
        <el-row v-if="$auth.isOspAdmin()" :gutter="24">
          <el-col :span="12">
            <base-select
              v-model="form.partnerOspId"
              :items="partnersData"
              value-key="name"
              :placeholder="
                $t('corporate_accounts_management.view_all_page.input_select')
              "
              filterable
              clearable
            >
              <template v-slot:option="{ items }">
                <el-option
                  v-for="item in items"
                  :key="item.extId"
                  :value="item.ospId"
                  :label="item.name"
                >
                  <div class="filters__select-option">
                    <i class="ri-building-4-line"></i>
                    {{ item.name }}
                  </div>
                </el-option>
              </template>
            </base-select>
          </el-col>
          <el-col :span="12">
            <base-input
              class="search"
              ref="search"
              prop="search"
              v-model.trim="form.budgetPaymentReference"
              name="search"
              auto-complete="off"
              :placeholder="
                $t('corporate_accounts_management.view_all_page.input_budget')
              "
            >
              <template #prependInput>
                <i class="ri-search-line"></i>
              </template>
            </base-input>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table
      id="corporateAccountsTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="pagination.sort"
      row-class-name="table-row clickable"
      @row-click="handleDetails"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column prop="icon" width="37">
        <template>
          <i class="ri-building-4-line"></i>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="name"
        show-overflow-tooltip
        :label="$t('general.label.name')"
        min-width="110"
      ></el-table-column>
      <el-table-column
        v-if="$auth.isOspAdmin()"
        prop="extId"
        show-overflow-tooltip
        :label="$t('general.label.extid')"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="active"
        :label="$t('user_management.manage_app_users_page.table_header.active')"
        width="100"
      >
        <template slot-scope="{ row }">
          <i
            class="activity-status"
            :class="
              row.active ? 'active ri-check-line' : 'inactive ri-close-line'
            "
          >
          </i>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="180">
        <template slot-scope="{ row }">
          <base-button
            @click.stop="handleDetails(row)"
            size="small"
            :label="
              $t(
                'corporate_accounts_management.view_all_page.button_see_details'
              )
            "
          />
          <el-dropdown trigger="click">
            <base-button
              size="small"
              append-icon="ri-arrow-down-s-line"
              :label="
                $t('corporate_accounts_management.view_all_page.button_more')
              "
              @click.stop.prevent="() => {}"
            />
            <el-dropdown-menu
              slot="dropdown"
              class="corporateAccountsTable__row-actions-group"
            >
              <el-dropdown-item @click.native="handleManageUsers(row)">
                <i class="ri-group-line"></i>
                {{
                  $t(
                    'corporate_accounts_management.view_all_page.button_manage_users'
                  )
                }}
              </el-dropdown-item>
              <el-dropdown-item @click.native="handleManageProducts(row)">
                <i class="ri-ticket-line"></i>
                {{
                  $t(
                    'corporate_accounts_management.view_all_page.button_manage_products'
                  )
                }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <template #footer v-if="totalPages > 1">
      <base-pagination v-model="pagination.page" :pages="totalPages" />
    </template>
  </page-layout>
</template>

<script>
import store from '@/store'
import PageLayout from '../../layouts/PageLayout'
import { debounce, orderBy } from 'lodash'
import { mapGetters } from 'vuex'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'
import PartnerRepository from '@/app/repositories/PartnerRepository'

const defaultSort = { order: 'ascending', prop: 'name' }

const corporateAccountRepository = new CorporateAccountRepository()
const partnerRepository = new PartnerRepository()

export default {
  name: 'CorporateAccountsViewAll',
  components: {
    PageLayout
  },
  mixins: [TableLoader, CancelRequest],
  data () {
    const { partner } = store.getters['corporateAccount/corporateAccount']
    return {
      dirtyData: {
        content: [],
        totalPages: 1
      },
      loading: false,
      form: {
        search: '',
        inactive: false,
        partnerOspId: this.$auth.isOspAdmin()
          ? this.$route.query.partnerOspId || null
          : this.$route.query.partnerOspId || partner.ospId,
        budgetPaymentReference: ''
      },
      pagination: {
        page: 0,
        size: 25,
        sort: defaultSort
      },
      requestSource: null,
      partnersData: []
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    'pagination.sort': debounce(function () {
      this.fetchData()
    }, 200),
    'form.inactive': debounce(function () {
      this.fetchData()
    }, 200),
    'form.partnerOspId': debounce(function () {
      this.fetchData()
    }, 200),
    'form.search': debounce(function () {
      this.fetchData()
    }, 700),
    'form.budgetPaymentReference': debounce(function () {
      this.fetchData()
    }, 700),
    totalPages (v) {
      if (v && this.pagination.page >= v) {
        this.pagination.page = v - 1
      }
    }
  },
  computed: {
    ...mapGetters({
      email: 'user/email',
      corporateAccount: 'corporateAccount/corporateAccount'
    }),
    selectedPartner () {
      return this.filters.partnerOspId
        ? this.partnersData.find(p => p.ospId === this.filters.partnerOspId) ||
            {}
        : {}
    },
    filters () {
      return {
        active: !this.form.inactive,
        pagination: {
          page: this.pagination.page,
          size: this.pagination.size,
          sort: {
            sortOrders: [
              {
                direction: (
                  this.pagination.sort.order || defaultSort.order
                ).toUpperCase(),
                property: this.pagination.sort.prop
              }
            ]
          }
        },
        nameOrExtIdLike: this.form.search || undefined,
        partnerOspId: this.form.partnerOspId || null,
        budgetPaymentReference: this.form.budgetPaymentReference
      }
    },
    tableData () {
      return this.dirtyData.content || []
    },
    totalPages () {
      try {
        return this.dirtyData.totalPages || 1
      } catch (e) {
        return 1
      }
    }
  },
  methods: {
    async fetchData () {
      this.TableLoader_show()
      try {
        await corporateAccountRepository.getByFilter(this.filters, {
          cancelToken: this.CancelRequest_token(),
          onSuccess: response => {
            this.dirtyData = response
          }
        })
      } catch (e) {
        console.error(e)
      }
      this.TableLoader_hide()
    },
    sortChange (sort) {
      this.pagination.sort = sort
    },
    handleDetails (corporateAccount) {
      this.TableLoader_show()
      this.$router.push({
        name: 'CompanySettingsDetails',
        query: {
          corporateAccountOspId: corporateAccount.ospId
        }
      })
    },
    handleAddCorporateAccount () {
      this.$router.push({
        name: 'CorporateAccountsAdd',
        query: {
          partnerOspId: this.$auth.isOspAdmin()
            ? this.filters.partnerOspId
            : undefined,
          partnerName: this.$auth.isOspAdmin()
            ? this.selectedPartner.name
            : undefined
        }
      })
    },
    handleManageUsers (corporateAccount) {
      this.TableLoader_show()
      this.$router.push({
        name: 'ManageAppUsers',
        query: {
          corporateAccountOspId: corporateAccount.ospId
        }
      })
    },
    handleManageProducts (corporateAccount) {
      this.TableLoader_show()
      this.$router.push({
        name: 'CompanyProducts',
        query: {
          corporateAccountOspId: corporateAccount.ospId
        }
      })
    },
    async fetchPartner () {
      try {
        await partnerRepository.getAll(
          { active: true },
          {
            onSuccess: response => {
              this.partnersData = orderBy(response, 'name')
            }
          }
        )
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted () {
    this.fetchData()
    this.fetchPartner()
  }
}
</script>
