<template>
  <el-form ref="form" :model="form" @submit.prevent.native="() => {}">
    <el-alert
      type="info"
      :closable="false"
      show-icon>
      <span v-html="$t('corporate_accounts_management.add_page.set_products.alert')"></span>
    </el-alert>
    <base-section
      :title="$t('products_management.title')"
      :is-flex="true"
      v-loading="loading"
    >
      <base-transfer
        :top-titles="[$t('corporate_accounts_management.add_page.set_products.transfer.left_panel_title'), $t('corporate_accounts_management.add_page.set_products.transfer.right_panel_title')]"
         filterable
        :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
         v-model="form.allowedProducts"
        :height="470"
        :with-pagination="true"
        :full-width="true"
        :data="products">
      </base-transfer>
    </base-section>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash'

export default {
  name: 'CompanyProducts',
  components: {},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      key: '',
      loading: false,
      products: []
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount'
    }),
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    fetchProducts () {
      this.loading = true
      return this.$api.product.findAll({
        onSuccess: r => {
          this.products = sortBy(r.map(p => {
            return {
              key: p.ospId,
              label: this.$trans(p.productLabel)
            }
          }), p => p.label)
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    fetchServiceResources () {
      return this.$api.product.findAllServiceResources({
        onSuccess: r => {
          this.form.allowedServiceResources = r.map(res => res.ospId)
        }
      })
    }
  },
  mounted () {
    this.fetchProducts()
    this.fetchServiceResources()
  }
}
</script>
