<template>
  <page-layout
    :title="$t('corporate_accounts_management.add_page.title')"
    :sub-title="subtitle"
    :has-back-button="true"
    :emit-back="true"
    @back="back"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :loading="saving"
        :label="buttonLabel"
        @click="next"
      ></base-button>
    </template>
    <company-details
      ref="companyDetails"
      v-if="page === 0"
      v-model="form.corporateAccountData"
    ></company-details>
    <company-budgets
      ref="companyBudgets"
      v-if="page === 1"
      v-model="form"
    ></company-budgets>
    <company-products
      ref="companyProducts"
      v-if="page === 2"
      v-model="form"
    ></company-products>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import CompanyDetails from '@/components/AddCorporateAccount/CompanyDetails'
import CompanyBudgets from '@/components/AddCorporateAccount/CompanyBudgets'
import CompanyProducts from '@/components/AddCorporateAccount/CompanyProducts'
import { mapGetters } from 'vuex'

export default {
  name: 'CorporateAccountsAdd',
  components: {
    PageLayout,
    CompanyDetails,
    CompanyBudgets,
    CompanyProducts
  },
  data () {
    return {
      saving: false,
      page: 0,
      pages: 2,
      form: {
        corporateAccountData: {
          showExtraDetails: false,
          name: null,
          contractId: null,
          street: null,
          streetNumber: null,
          zipCode: null,
          city: null,
          country: null,
          iban: null,
          bic: null,
          poNumber: null,
          requires2fa: false,
          externalReferenceMandatory: false,
          extId: null,
          extIdEqualToName: true,
          vatLiable: true
        },
        allowedBudgetTypes: [],
        allowedProducts: [],
        allowedServiceResources: []
      }
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/corporateAccount'
    }),
    partner () {
      if (this.$auth.isOspAdmin() && this.$route.query.partnerOspId) {
        return {
          ospId: this.$route.query.partnerOspId
        }
      }
      return this.corporateAccount.partner
    },
    subtitle () {
      if (this.page === 0) {
        return this.$t(
          'corporate_accounts_management.add_page.subtitle_company_details'
        )
      }
      if (this.page === 1) {
        return this.$t(
          'corporate_accounts_management.add_page.subtitle_set_budgets'
        )
      }
      if (this.page === 2) {
        return this.$t(
          'corporate_accounts_management.add_page.subtitle_set_products'
        )
      }
      return ''
    },
    buttonLabel () {
      if (this.page === 0) {
        return this.$t(
          'corporate_accounts_management.add_page.button_label_on_details'
        )
      }
      if (this.page === 1) {
        return this.$t(
          'corporate_accounts_management.add_page.button_label_on_budgets'
        )
      }
      if (this.page === 2) {
        return this.$t(
          'corporate_accounts_management.add_page.button_label_on_products'
        )
      }
      return ''
    }
  },
  methods: {
    back () {
      if (this.page) {
        this.page--
      } else {
        this.$router.back()
      }
    },
    next () {
      if (!this.validate()) {
        return false
      }
      if (this.page < this.pages) {
        this.page++
      } else {
        this.submit()
      }
    },
    validate () {
      if (this.page === 0) {
        const {
          accountSection,
          companyAddressSection
        } = this.$refs.companyDetails.$refs
        if (accountSection) {
          accountSection.$v.form.$touch()
          if (accountSection.$v.form.$invalid) {
            this.$scrollTo('#accountSection')
            return false
          }
        }
        if (companyAddressSection) {
          companyAddressSection.$v.form.$touch()
          if (companyAddressSection.$v.form.$invalid) {
            this.$scrollTo('#companyAddressSection')
            return false
          }
        }
      } else if (this.page === 1) {
        this.$refs.companyBudgets.$v.selectedBudgetsValidation.$touch()
        return !this.$refs.companyBudgets.$v.selectedBudgetsValidation.$invalid
      }
      return true
    },
    submit () {
      this.saving = true
      // this.form.corporateAccountData.extId = upperFirst(camelCase(this.form.corporateAccountData.name))
      this.$api.corporateAccount.create(this.form, {
        onSuccess: ({ ospId }) => {
          return this.$router.push({
            name: 'CompanySettingsDetails',
            query: {
              corporateAccountOspId: ospId
            }
          })
        },
        onFinally: () => {
          this.saving = false
        }
      })
    }
  },
  mounted () {
    this.form.corporateAccountData.partner = this.partner
  }
}
</script>
