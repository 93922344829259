<template>
  <el-form ref="form" :model="form" @submit.prevent.native="() => {}">
    <el-alert
      type="info"
      :closable="false"
      show-icon>
      <span v-html="$t('corporate_accounts_management.add_page.set_budgets.alert')"></span>
    </el-alert>
    <base-section
      :title="$t('user_management.section.budgets.title')"
    >
      <el-row ref="budgets" :key="key" v-loading="loading">
        <template v-for="(type, index) in types">
          <el-col :span="24" :key="index + 'checkbox'">
            <base-checkbox
              :label="type.name"
              :value="selectedBudgets[type.budgetType]"
              @input="(v) => change(type.budgetType, v)"
              :explanation="type.info"
            />
          </el-col>
        </template>
        <p v-if="$v.selectedBudgetsValidation.$invalid && $v.selectedBudgetsValidation.$dirty" class="error validation-error">
          {{ $t('validations.required.with_label', { label: 'Budget' }) }}
        </p>
      </el-row>
    </base-section>
  </el-form>
</template>

<script>
import { keys, zipObject } from 'lodash'
import BudgetTypeRepository from '@/app/repositories/BudgetTypeRepository'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

const budgetTypeRepository = new BudgetTypeRepository()

export default {
  name: 'CompanyBudgets',
  components: {},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      key: '',
      types: [],
      loading: false
    }
  },
  validations: {
    selectedBudgetsValidation: {
      required
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount'
    }),
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    selectedBudgetsValidation () {
      return this.form.allowedBudgetTypes.join(',')
    },
    selectedBudgets: {
      get () {
        const budgetTypes = this.form.allowedBudgetTypes || []
        return zipObject(budgetTypes, new Array(budgetTypes.length).fill(true))
      }
    }
  },
  methods: {
    change (target, value) {
      this.$set(this.selectedBudgets, target, value)
      this.form.allowedBudgetTypes = keys(this.selectedBudgets).filter(v => this.selectedBudgets[v])
      this.key = this.$key()
    },
    fetchBudgetTypes () {
      this.loading = true
      return budgetTypeRepository.getAll(this.corporateAccount.extId,
        {
          onSuccess: r => {
            this.types = r
          },
          onFinally: () => {
            this.loading = false
          }
        })
    }
  },
  mounted () {
    this.fetchBudgetTypes()
  }
}
</script>
